import { useDialog } from '~/components/ui/dialog/useDialog'
import { DIALOG } from '~/constants/shared/dialog'

const passwordConfirmEvents = {
  onConfirm: () => {}
}

interface Events {
  onConfirm: () => void
}

export function usePasswordConfirmModal(events?: Events) {
  const { dialog, close, open } = useDialog(DIALOG.PASSWORD_CONFIRM)

  if (events?.onConfirm && import.meta.client) {
    passwordConfirmEvents.onConfirm = events.onConfirm
  }

  function confirm() {
    passwordConfirmEvents.onConfirm()
  }

  return { dialog, open, close, confirm }
}
